<template>
  <div>
    <v-form ref="form" :readonly="value.id > 0 && !canEditSetupTables" @submit.prevent="onSubmit">
      <v-container>
        <v-row>
          <v-col cols="12" md="12" class="px-0">
            <v-btn @click="cancel(false)"> cancel </v-btn>
            <v-btn v-if="canEditSetupTables" class="float-right" color="green" dark type="submit"> save </v-btn>
          </v-col>
        </v-row>
      </v-container>
      <v-card>
        <v-container>
          <v-row dense>
            <v-col cols="12" md="6">
              <v-text-field v-model.trim="newTripType.name" label="Type" :rules="[required]"></v-text-field>
            </v-col>
            <v-col cols="12" md="4">
              <v-select
                :menu-props="{ bottom: true, offsetY: true }"
                label="Category"
                v-model="newTripType.categoryId"
                :items="this.tripCategories"
                item-text="name"
                item-value="id"
                outlined
                class="mb-0"
                :rules="[required]"
              >
              </v-select>
            </v-col>
            <v-col cols="12" md="2">
              <v-checkbox v-model="newTripType.active" label="Active" :rules="[required]"></v-checkbox>
            </v-col>
          </v-row>

          <v-row dense>
            <v-col cols="12" md="6">
              <v-checkbox
                v-model="newTripType.availableOnForm"
                label="Available on Trip Request Form"
                :rules="[required]"
                dense
              ></v-checkbox>
            </v-col>
            <v-col cols="12" md="6">
              <v-checkbox
                v-model="newTripType.allowEventRates"
                label="Allow Trip Event Rates"
                :rules="[required]"
                dense
              ></v-checkbox>
            </v-col>
          </v-row>

          <v-row class="my-0 py-0" dense>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="newTripType.costPerMile"
                label="Cost Per Mile"
                :rules="[required, numeric]"
                type="number"
                prepend-inner-icon="mdi-currency-usd"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="newTripType.planningCost"
                label="Planning Cost/Mile"
                :rules="[required, numeric]"
                type="number"
                prepend-inner-icon="mdi-currency-usd"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row class="my-0 py-0" dense>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="newTripType.additionalCost"
                label="Additional Cost/Mile"
                :rules="[required, numeric]"
                type="number"
                prepend-inner-icon="mdi-currency-usd"
              ></v-text-field>
            </v-col>
            <v-col cols="12" md="6">
              <v-text-field
                v-model="newTripType.standardFee"
                label="Standard Fee"
                :rules="[required, numeric]"
                type="number"
                prepend-inner-icon="mdi-currency-usd"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="12">
              <v-select
                :menu-props="{ bottom: true, offsetY: true }"
                label="Vehicle Type(s)"
                v-model="selectedVehicleTypes"
                :items="vehicleTypes"
                item-text="name"
                item-value="id"
                outlined
                hide-details
                class="mb-0"
                multiple
              >
                <template v-slot:prepend-item>
                  <v-list-item ripple @mousedown.prevent @click="toggle">
                    <v-list-item-action>
                      <v-icon :color="selectedVehicleTypes?.length > 0 ? 'primary' : ''">
                        {{ icon }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title> Select All </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-2"></v-divider>
                </template>
                <template v-slot:selection="{ item, parent }">
                  <v-chip>
                    <span class="pr-2">
                      {{ item.name }}
                    </span>
                    <v-icon @click="parent.selectItem(item)"> $delete </v-icon>
                  </v-chip>
                </template>
              </v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" md="6">
              <v-select
                :menu-props="{ bottom: true, offsetY: true }"
                label="Default Vehicle Type"
                v-model="newTripType.defaultVehicleTypeId"
                :items="filteredVehicleTypes"
                item-text="name"
                item-value="id"
                outlined
                hide-details
                clearable
                class="mb-0"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-container>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { required, numeric } from '@/util/rules';
import { handleErrors } from '@/util';

export default {
  data: () => ({
    required,
    numeric,
    handleErrors,
    id: null,
    saved: false,
    newTripType: {
      name: '',
      active: true,
      categoryId: null,
      availableOnForm: true,
      allowEventRates: false,
      costPerMile: 0,
      planningCost: 0,
      additionalCost: 0,
      standardFee: 0,
      vehicleTypes: [],
      defaultVehicleTypeId: 0,
    },
    selectedVehicleTypes: [],
  }),
  computed: {
    ...mapGetters('user', ['me', 'canEditSetupTables']),
    filteredVehicleTypes() {
      if (this.newTripType.vehicleTypes && this.newTripType.vehicleTypes.length)
        return this.vehicleTypes.filter((e) => this.newTripType.vehicleTypes.includes(e.id));
      return this.vehicleTypes;
    },
    selectAllVehicleTypes() {
      return this.selectedVehicleTypes?.length === this.vehicleTypes?.length;
    },
    selectSomeVehicleTypes() {
      return this.selectedVehicleTypes?.length > 0 && !this.selectAllVehicleTypes;
    },
    icon() {
      if (this.selectAllVehicleTypes) return 'mdi-close-box';
      if (this.selectSomeVehicleTypes) return 'mdi-minus-box';
      return 'mdi-checkbox-blank-outline';
    },
  },
  methods: {
    toggle() {
      this.$nextTick(() => {
        if (this.selectAllVehicleTypes) {
          this.selectedVehicleTypes = [];
        } else {
          this.selectedVehicleTypes = this.vehicleTypes.slice();
        }
      });
    },
    async cancel(fromSave) {
      let ok = true;
      const changesMade = this.compareValues();
      if (!fromSave && changesMade)
        ok = await this.$myconfirm('Are you sure you want to close this form? Your changes will not be saved.');
      if (ok) {
        this.$emit('closeForm');
      }
    },
    onSubmit() {
      if (!this.$refs.form.validate()) {
        this.$myalert.error('Please complete all required fields');
        return;
      }
      this.newTripType.vehicleTypes = this.selectedVehicleTypes;
      this.newTripType.vehicleTypes = 
          Array.isArray(this.selectedVehicleTypes) && typeof this.selectedVehicleTypes[0] === 'object'
            ? this.selectedVehicleTypes.map((v) => v.id)
            : this.selectedVehicleTypes,
      this.$emit('submit', { id: this.id, ...this.newTripType });
      this.saved = true;
    },
    compareValues() {
      if (!this.id && (this.newTripType.name || this.newTripType.categoryId)) return true;
      if (!this.id) return false;
      if (
        this.newTripType.name != this.value.name ||
        this.newTripType.active != this.value.active ||
        this.newTripType.categoryId != this.value.categoryId ||
        this.newTripType.availableOnForm != this.value.availableOnForm ||
        this.newTripType.allowEventRates != this.value.allowEventRates ||
        this.newTripType.costPerMile != this.value.costPerMile ||
        this.newTripType.planningCost != this.value.planningCost ||
        this.newTripType.additionalCost != this.value.additionalCost ||
        this.newTripType.standardFee != this.value.standardFee ||
        this.newTripType.vehicleTypes != this.value.vehicleTypes
      )
        return true;
      return false;
    },
  },
  props: {
    value: {
      type: Object,
      default: () => this.newTripType,
    },
    tripCategories: {
      type: Array,
      default: () => [],
    },
    vehicleTypes: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    value: {
      immediate: true,
      deep: true,
      handler: function (value) {
        this.id = value.id;
        if (value.id && !this.saved) {
          this.selectedVehicleTypes = value.vehicleTypes;
          this.newTripType = { ...value };
        } else if (value.id && this.saved) {
          this.saved = false;
          this.cancel(true);
        }
      },
    },
  },
};
</script>
